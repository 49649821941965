<template>
    <div>        
        <input type="text" class="position-fixed top-0 left-0"  style="display: none; color: #fff; z-index: 99999; opacity: 0;" id="infoInputCopy"/>
        <div class="d-flex flex-column" :style="cardHeight">
            <div class="d-flex flex-fill flex-grow-1" style="height: 100px;" >                
                <!-- /.card-header -->
                <div class="flex-fill d-flex flex-column card-body p-1 pt-0" v-loading="isLoading">
                    <div>
                        <div>
                            <div class="mb-1"><h4><strong>Proccess name</strong></h4></div>
                        </div>
                    </div>
                    <VuePerfectScrollbar class="table-responsive p-0 flex-fill"
                                         :settings="{ useBothWheelAxes: false, suppressScrollX: true }">
                        <div v-if="proccessList.length > 0"
                             class="text-nowrap">
                            <div  class="row">
                                <div :key="element.id" v-for="element in proccessList"
                                     class="col-12 col-md-4 pb-0 pt-1">
                                    <el-card :body-style="{ padding: '0.5rem' }">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img :src="`/img/software-icon/${element.proccess}.png`" style="width: 1.3rem;"
                                                     onerror="this.src='/img/software.svg'" />
                                            </div>
                                            <div class="ml-3">
                                                <div><strong>{{element.proccess}}</strong></div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                        <div v-else class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                            <div class="align-self-center text-center">
                                <div class="mb-5"><img src="/static/image/no-job-icon.svg" style="opacity: 0.7;"></div>
                                <h4><strong class="text-muted">There is no Proccess has been added</strong></h4>
                                <p class="text-muted">Proccess of this machine show here !</p>
                            </div>
                        </div>
                    </VuePerfectScrollbar>
                </div>
                <!-- /.card-body -->
            </div>
        <!-- /.card -->

        </div>        

    </div>
</template>


<script>
    import vmMachineApi from "@/api/gpuHub/vmMachines";
    import baseComponent from "@/scripts/baseComponent";

    export default {
        extends: baseComponent,
        props: {
            cardHeight: {
                type: String,
                default: 'height: calc(100dvh - 5.5rem);'
            },
            detailMachineItem: {
                type: Object,
                default: null,
            },
        },
        watch: {
            detailMachineItem(newVal) {
                console.log("detailMachineItem", newVal);
                if (this.detailMachineItem !== null) {
                    this.getProccessByMachineId();
                }
            },
        },
        data() {
            return {
                proccessList: [],
            };
        },
        mounted() {
            this.getProccessByMachineId();
        },
        methods: {
            getProccessByMachineId() {
                vmMachineApi
                    .getProccessByMachineId(this.detailMachineItem.vmMachineId)
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            console.log("getProccessByMachineId", response.data.data);
                            this.$set(this, "proccessList", response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
    }
</script>